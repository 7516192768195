
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { BrowserMultiFormatReader, Exception } from '@zxing/library'
import BaseDataTable from "@/general/components/general/BaseDataTable.vue";
import Logo from '@/assets/images/logo.png'

@Component({
  components: {BaseDataTable}
})
export default class ScanComponent extends Vue {
  @Prop({ default: false }) backButton: boolean

  logo = '/assets/img/logo.png'
  codeReader = new BrowserMultiFormatReader()
  isMediaStreamAPISupported = navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices()

  mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception('Media Stream API is not supported')
    }
    this.start()
  }

  beforeDestroy() {
    this.codeReader.reset()
  }

  imgError() {
    console.log('Using fallback image')
    this.logo = Logo
  }

  start() {
    this.codeReader.decodeFromVideoDevice(
      null,
      //@ts-ignore
      this.$refs.scanner,
      (result, err) => {
        if (result) {
          this.$emit('decode', result.getText())
          console.log(result)
        }
      }
    )
  }

  get isMobile(): boolean {
    return window.innerWidth < 960
  }
}
